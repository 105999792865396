<template>
  <LoginForm />
</template>

<script>
import LoginForm from "../components/LoginForm";

export default {
  name: 'Login',
  components: {
    LoginForm,
  }
}
</script>