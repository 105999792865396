<template>
  <v-form
      ref="form"
      lazy-validation
  >
    <v-text-field
        v-model="username"
        :counter="50"
        label="Имя"
        required
    ></v-text-field>

    <v-text-field
        v-model="password"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPassword ? 'text' : 'password'"
        name="input-10-1"
        label="Пароль"
        counter
        required
        @click:append="showPassword = !showPassword"
    ></v-text-field>

    <v-btn
        color="success"
        class="mr-4"
        @click="handleSubmit"
    >
      Авторизация
    </v-btn>
  </v-form>
</template>

<script>
import authApi from "../api/auth"
import storage from "../plugins/storage"

export default {
  name: 'LoginForm',
  data(){
    return {
      username : '',
      password : '',
      showPassword : false,
    }
  },
  methods : {
    handleSubmit(e){
      e.preventDefault()

      if (this.password.length > 0) {
        authApi.login(this.username, this.password).then(response => {
          if (response.data.token) {
            storage.setToken(response.data.token)
            this.$emit('loggedIn')

            if (this.$route.params.nextUrl) {
              this.$router.push(this.$route.params.nextUrl)
            } else {
              this.$router.push({ name: 'home'})
            }
          }
        }).catch(function (error) {
          console.error(error);
        });
      }
    }
  }
}
</script>